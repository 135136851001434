
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homescreen from './screens/Homescreen';
import Aboutus from './screens/Aboutus';

import Contact from './screens/Contact';
import Allcategory from './component/Allcategory';
import Pcd from './screens/Pcd';
import Third from './screens/Third';
import Thanks  from './screens/Thanks'
import Product from './component/Product';

function App() {
  return (
   <BrowserRouter>

    <Routes>
      <Route path='/' exact element={<Homescreen />}/>
      <Route path='/about-us' element={<Aboutus />}/>
      <Route path='/contact-us' element={<Contact />}/>
      <Route path='/:category' element={<Allcategory />}/>
      <Route path='/pcd-pharma-franchise' element={<Pcd />}/>
      <Route path='/third-party-manufacturing' element={<Third />}/>
      <Route path='/thank-you-for-contacting-us' element={<Thanks />}/>
      <Route exact path='/product/:slug' element={<Product />}/>

      
    </Routes>
   </BrowserRouter>
  );
}

export default App;
