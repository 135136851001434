import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {


    const [cate, setCate] = useState([])
    const allcategory = () => {
        fetch('https://www.dashboard.avosiapharma.com/api/allCategories').then((res) => res.json())
            .then((result) => {
                setCate(result.cat)
            })
            .catch((err) => {
                console.log(err)
            })

    }


    useEffect(() => {
        allcategory()
    }, [])



    return (
        <div>
            <section className="footer-section">
                <div className="container">
                    <figure className="footer-leftimage mb-0">
                        <img src="assets/images/footer-leftimage.png" alt="" className="img-fluid" />
                    </figure>
                    <figure className="mb-0 about-leftcircle">
                        <img src="assets/images/about-rightcircle.png" alt="" className="img-fluid" />
                    </figure>
                    <div className="middle-portion">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6 col-12">
                                <div className="first-column">
                                    <h4 className="heading text-white">About us</h4>
                                    <p className="text-size-18 footer-text">Avosia Pharma is one of the leading Pharma Company with a most exclusive range of products. We are a leading pharmaceutical company dedicated to improving health and providing top-quality formulations. </p>
                                    <div className="lower">
                                        <div className="lower-content">
                                            <figure className="icon">
                                                <img src="assets/images/footer-callicon.png" alt="" className="img-fluid" />
                                            </figure>
                                            <div className="content">
                                                <span className="text-white">Call us:</span>
                                                <Link className="text-size-18 mb-0 text text-decoration-none" to="tel:+91 9463813290">+91 9463813290</Link>
                                            </div>
                                        </div>
                                        <div className="lower-content">
                                            <figure className="icon">
                                                <img src="assets/images/footer-emailicon.png" alt="" className="img-fluid" />
                                            </figure>
                                            <div className="content">
                                                <span className="text-white">Email us:</span>
                                                <Link to="mailto:avosiapharma@gmail.com" className="text-size-18 mb-0 text-decoration-none">avosiapharma@gmail.com</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-12 col-12 d-md-block d-none">
                                <div className="links list-pd">
                                    <h4 className="heading text-white">Quick Links</h4>
                                    <ul className="list-unstyled mb-0">
                                        <li><Link to="/" className="text-size-18 text text-decoration-none">Home</Link></li>
                                        <li><Link to="/about-us" className="text-size-18 text text-decoration-none">About</Link></li>
                                        <li><Link to="/pcd-pharma-franchise" className="text-size-18 text text-decoration-none">PCD Pharma Franchise</Link></li>
                                        <li><Link to="/third-party-manufacturing" className="text-size-18 text text-decoration-none">Third Party Manufacturing</Link></li>
                                        <li><Link to="#" className="text-size-18 text text-decoration-none">Blog</Link></li>
                                        <li><Link to="/contact-us" className="text-size-18 text text-decoration-none">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 d-lg-block d-none">
                                <div className="links">
                                    <h4 className="heading text-white">Human Product List</h4>
                                    <ul className="list-unstyled mb-0">

                                    <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/tablets">Tablets</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/capsules">Capsules</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/injections">Injections</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/syrup">Syrup</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/dry-syrup">Dry Syrup</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/Softget-Capsules">Softget Capsules</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/Paedeatric-drops">Paedeatric Drops</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/Sachets">Sachets</Link></li>
                                        <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/others">Others</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-12 d-lg-block d-none">
                                <div className="links">
                                    <h4 className="heading text-white">Veterinary product list</h4>
                                    <ul className="list-unstyled mb-0">

                                    <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/tablet-bolus">Tablet Bolus</Link></li>
                                       <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/liquid-injections">Liquid Injections</Link></li>
                                       <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/dry-injections">Dry Injections</Link></li>
                                       <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/feed-supplements">Feed Supplements</Link></li>
                                       <li className="nav-item"><Link className="text-size-18 text text-decoration-none" to="/veterinary-other">Other</Link></li>
                                    </ul>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="container">
                            <div className="row copyright-border">
                                <div className="col-lg-6 col-md-6 col-sm-12 co-12 column">
                                    <p className="text-size-16">Copyright ©2023  All Rights Reserved . Designed & Developed By <Link to="https://www.rednirus.in/">Rednirus Digital Media</Link></p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 co-12 d-md-block d-none">
                                    <div className="social-icons position-relative">
                                        <ul className="list-unstyled position-absolute">
                                            <li><Link to="#" className="text-decoration-none"><i className="fa-brands fa-facebook-f social-networks"></i></Link></li>
                                            <li><Link to="#" className="text-decoration-none"><i className="fa-brands fa-twitter social-networks"></i></Link></li>
                                            <li><Link to="#" className="text-decoration-none"><i className="fa-brands fa-google-plus-g social-networks"></i></Link></li>
                                            <li><Link to="#" className="text-decoration-none"><i className="fa-brands fa-instagram social-networks"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <figure className="footer-dotimage mb-0">
                        <img src="assets/images/footer-dotimage.png" alt="" className="img-fluid" />
                    </figure>
                    <figure className="footer-leftlayer mb-0">
                        <img src="assets/images/footer-leftlayer.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>
            <div id="sidebar">
                <ul>
                    <li>
                        <Link to="tel:+91 7009781482">
                            <img src="/assets/images/call.png" width={50}/>
                        </Link>
                    </li>
                    <li>
                        <Link to="https://api.whatsapp.com/send?phone=+91 7009781482&amp;text=Thank you for contacting Avosia Pharma
                           Please let us know how can we help you...
                      ">
                            <img src="/assets/images/whatsapp.png" width={50}/>
                        </Link>
                    </li>
                    <li>
                        <Link to="https://www.dashboard.avosiapharma.com/assets/img/product_list.pdf" download="download">
                            <img src="/assets/images/pdf.png" width={50}/>
                        </Link>
                    </li>
                </ul>



            </div>
        </div>
    )
}
