import React from 'react'

export default function Choose() {
    return (
        <div>
            <section class="skill-section">
                <figure class="service-rightlayer mb-0">
                    <img src="assets/images/service-leftlayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="skill_wrapper">
                                <figure class="offer-circleimage mb-0">
                                    <img src="assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                                </figure>
                                <figure class="skill-image mb-0">
                                    <img src="assets/images/why-choose-us.png" alt="" class="" />
                                </figure>
                                <figure class="service-dotimage mb-0">
                                    <img src="assets/images/service-dotimage.png" alt="" class="img-fluid" />
                                </figure>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="skill_content aos-init" data-aos="fade-right">
                                <h6>Choose us<div></div></h6>
                                <h2>Why People Mostly Love us</h2>
                                <p>As the Best PCD Pharma Franchise Company, Avosia Pharma is offering the best services.</p>
                                <div class="skill-measure">
                                    <p class="text text-size-18">Quality Assured Products</p>
                                    <div class="percentage">
                                        <span class="mb-0 counter text-size-18">75</span>
                                        <span class="mb-0 plus">%</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar1" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="skill-measure">
                                    <p class="text text-size-18">Extensive Product Range</p>
                                    <div class="percentage">
                                        <span class="mb-0 counter text-size-18">85</span>
                                        <span class="mb-0 plus">%</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar2" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="skill-measure">
                                    <p class="text text-size-18">Customer-Centric Approach</p>
                                    <div class="percentage">
                                        <span class="mb-0 counter text-size-18">60</span>
                                        <span class="mb-0 plus">%</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar3" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="skill-measure">
                                    <p class="text text-size-18">On-time Delivery of Products</p>
                                    <div class="percentage">
                                        <span class="mb-0 counter text-size-18">95</span>
                                        <span class="mb-0 plus">%</span>
                                    </div>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar4" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="service-leftlayer mb-0">
                    <img src="assets/images/service-rightlayer.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
    )
}
