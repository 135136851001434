import React from 'react'

export default function Counter() {
  return (
    <div>
      <div id="projectFacts" class="sectionClass">
    <div class="fullWidth eight columns">
        <div class="projectFactsWrap ">
            <div class="item wow fadeInUpBig animated animated" data-number="12" style={{visibility: "visible"}}>
                <i class="fa fa-briefcase"></i>
                <p id="number1" class="number">500</p>
                <span></span>
                <p>Product Range</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="55" style={{visibility: "visible"}}>
                <i class="fa fa-user"></i>
                <p id="number2" class="number">550</p>
                <span></span>
                <p>Happy clients</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="359" style={{visibility: "visible"}}>
                <i class="fa fa-coffee"></i>
                <p id="number3" class="number">50</p>
                <span></span>
                <p>Team</p>
            </div>
            <div class="item wow fadeInUpBig animated animated" data-number="246" style={{visibility: "visible"}}>
                <i class="fa fa-camera"></i>
                <p id="number4" class="number">10</p>
                <span></span>
                <p>Awards</p>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}
