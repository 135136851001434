import React from 'react'
import Header from '../component/common/Header'
import Footer from '../component/common/Footer'
import Offer from '../component/Offer'
import About from '../component/About'
import Category from '../component/Category'
import Need from '../component/Need'
import Choose from '../component/Choose'
import Counter from '../component/Counter'
import Testimonial from '../component/Testimonial'
import Banner from '../component/Banner'
import Benefits from '../component/Benefits'
import Contactus from '../component/Contactus'
import Slider from '../component/Slider'

export default function Homescreen() {
  return (
    <>
      <div className='banner_outer'>
        <Header />
        <Slider />
       

      </div>
      <Contactus />
      <Offer />
      <About />
      <Category />
      <Need />
      <Choose />

      <Benefits />
      <Counter />
      <Testimonial />


      <Footer />
    </>
  )
}
