import React from 'react'

export default function Offer() {
  return (
    <div>
      <section class="offer-section">
    <figure class="offer-toplayer mb-0">
        <img src="assets/images/offer-toplayer.png" alt="" class="img-fluid" />
    </figure>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="offer_content" data-aos="fade-up">
                    <h6>what We Offer</h6>
                    <h2>Why choose Avosia Pharma </h2>
                    <p>Choose Avosia Pharma for unrivaled quality and reliability in pharmaceutical products, backed by exceptional customer service and innovative solutions.</p>
                </div>
            </div>
        </div>
        <div class="row position-relative">
            <figure class="offer-circleimage mb-0">
                <img src="assets/images/offer-circleimage.png" alt="" class="img-fluid" />
            </figure>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 article_padding">
                <div class="offer_boxcontent">
                    <div class="upper_portion">
                        <figure class="mb-0"><img src="assets/images/pcd-pharma.png" class="article_img" alt="" /></figure>
                        <div class="image_content">
                            <figure class="offer-icon">
                                <img src="assets/images/offer-icon1.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="lower_portion_wrapper">
                        <div class="lower_portion">
                            <h4 class="mb-0">PCD Pharma Franchise</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 article_padding">
                <div class="offer_boxcontent">
                    <div class="upper_portion">
                        <figure class="mb-0"><img src="assets/images/third-party-manufcaturing.png" class="article_img" alt="" /></figure>
                        <div class="image_content">
                            <figure class="offer-icon offer-icon2">
                                <img src="assets/images/offer-icon2.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="lower_portion_wrapper">
                        <div class="lower_portion">
                            <h4 class="mb-0">Third Party Manufacturing</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 article_padding">
                <div class="offer_boxcontent mb-0">
                    <div class="upper_portion">
                        <figure class="mb-0"><img src="assets/images/business.png" class="article_img" alt="" /></figure>
                        <div class="image_content">
                            <figure class="offer-icon">
                                <img src="assets/images/offer-icon3.png" alt="" class="img-fluid" />
                            </figure>
                        </div>
                    </div>
                    <div class="lower_portion_wrapper">
                        <div class="lower_portion">
                            <h4 class="mb-0">Business opportunity</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <figure class="offer-bottomlayer mb-0">
        <img src="assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
    </figure>
</section>
    </div>
  )
}
