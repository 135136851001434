import React from 'react'

export default function Slider() {
    return (
        <div class="headers">
            <div class="owl-carousel owl-theme">
                <div class="item">
                    <img src="/assets/images/banner-slider-one.jpg" alt="images not found" />
                    <div class="cover">
                        <div class="container">
                            <div class="header-content">
                                <div class="line"></div>
                                <h2>Welcome to</h2> 
                                <h1>Avosia Pharma</h1>
                                <h4>Avosia Pharma is one of the leading Pharma Company <br /> with a most exclusive range of products. </h4>
                               
                            </div>
                        </div>
                     </div>
                </div>                    
                <div class="item">
                    <img src="/assets/images/banner-slider-two.jpg" alt="images not found" />
                    <div class="cover">
                        <div class="container">
                            <div class="header-content">
                                <div class="line animated bounceInLeft"></div>
                                <h2>WHAT WE OFFER</h2>
                                <h1>WHY CHOOSE AVOSIA PHARMA</h1>
                                <h4>Choose Avosia Pharma for unrivaled quality and reliability in pharmaceutical products, <br></br>backed by exceptional customer service and innovative solutions.</h4>
                            </div>
                        </div>
                     </div>
                </div>                
                
            </div>
        </div>
    )
}
