import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom' 

export default function Header() {
    const [cate , setCate]=useState([])
    const allcategory = ()=>{
       fetch('https://www.dashboard.avosiapharma.com/api/allCategories').then((res)=>res.json())
       .then((result)=>{
        setCate(result.cat)
       })
       .catch((err)=>{
         console.log(err)
       })
        
    }


    useEffect(()=>{
        allcategory()
    },[])
 
  



  return (
    <div>
       <header className="header">
        <div className="main-header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <Link className="navbar-brand" to="/"><figure className="mb-0 banner-logo"><img src="/assets/images/logo.png" alt="" className="img-fluid" /></figure></Link>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" 
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            
                            <li className="nav-space nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dropdown-color navbar-text-color" to="#" id="navbarDropdownMenu" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"> About us </Link>
                                <div className="dropdown-menu drop-down-content">
                                    <ul className="list-unstyled drop-down-pages">
                                    <li className="nav-item"><Link className="dropdown-item nav-link" to="/about-us">About</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/pcd-pharma-franchise">PCD Pharma Franchise</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/third-party-manufacturing">Third Party Manufacturing</Link></li>
                                       
                                    </ul>
                                </div>
                            </li>
                          
                            <li className="nav-space nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dropdown-color navbar-text-color" to="#" id="navbarDropdownMenu" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">Human Product List </Link>
                                <div className="dropdown-menu drop-down-content">
                                    <ul className="list-unstyled drop-down-pages"> 
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/tablets">Tablets</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/capsules">Capsules</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/injections">Injections</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/syrup">Syrup</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/dry-syrup">Dry Syrup</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/Softget-Capsules">Softget Capsules</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/Paedeatric-drops">Paedeatric Drops</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/Sachets">Sachets</Link></li>
                                        <li className="nav-item"><Link className="dropdown-item nav-link" to="/others">Others</Link></li>
                                       
                                    </ul>
                                </div>
                            </li>
                            
                            <li className="nav-space nav-item dropdown">
                                <Link className="nav-link dropdown-toggle dropdown-color navbar-text-color" to="#" id="navbarDropdownMenu" role="button" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"> Veterinary product list </Link>
                                <div className="dropdown-menu drop-down-content">
                                    <ul className="list-unstyled drop-down-pages">
                                        {/* {
                                            cate.map(c=>{
                                                return (
                                                  
                                                   <li key={c.id} className="nav-item"><Link className="dropdown-item nav-link" to={`/${c.slug}`}>{c.name}</Link></li>
                                                  
                                                )
                                            })
                                        } */}
                                       
                                       <li className="nav-item"><Link className="dropdown-item nav-link" to="/tablet-bolus">Tablet Bolus</Link></li>
                                       <li className="nav-item"><Link className="dropdown-item nav-link" to="/liquid-injections">Liquid Injections</Link></li>
                                       <li className="nav-item"><Link className="dropdown-item nav-link" to="/dry-injections">Dry Injections</Link></li>
                                       <li className="nav-item"><Link className="dropdown-item nav-link" to="/feed-supplements">Feed Supplements</Link></li>
                                       <li className="nav-item"><Link className="dropdown-item nav-link" to="/veterinary-other">Other</Link></li>
                                    </ul>
                                </div>
                            </li>
                           
                            <li className="nav-space nav-item">
                                <Link className="nav-link" to="/contact-us">Contact</Link>
                            </li>
                            <li className="nav-space nav-item call">
                                <Link className="nav-link" to="tel:+91 7009781482">+91 7009781482</Link>
                            </li>
                        </ul>
                        {/* <div className="last_list">
                            <figure className="nav-phoneimage mb-0"><img className="img-fluid" src="assets/images/nav-phoneimage.png" alt="" /></figure>
                            <div className="content">
                                <p className="text-size-18 text-white">Call Anytime:</p>
                                <Link className="text-decoration-none last_list_atag" to="tel:+91 7009781482">+91 7009781482</Link>
                            </div>
                        </div> */}
                    </div>
                </nav>
            </div>
        </div>
    </header>
   
    </div>
  )
}
