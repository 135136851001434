import React from 'react'

export default function Need() {
  return (
    <div>
     <section class="consultation-section">
    <figure class="consultation-leftimage mb-0">
        <img src="assets/images/service-consultationleftimage.png" alt="" class="img-fluid" />
    </figure>
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-7 col-sm-12 col-12">
                <div class="consultation_content aos-init aos-animate" data-aos="fade-right">
                    <h6>WE ARE HERE TO guide you</h6>
                    <h2 class="text-white">NEED A FRANCHISE?</h2>
                    <p class="mb-0">Contact us and grab the opportunity at Avosia Pharma</p>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="consultation_wrapper">
                    <figure class="service-rightcircle mb-0">
                        <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                    </figure>
                    <a class="get_started button1 text-white text-decoration-none" href="/about-us">About us
                        <figure class="mb-0"><img src="assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                    </a>
                    <a class="get_started button2 text-white text-decoration-none" href="/contact-us">Contact Us
                        <figure class="mb-0"><img src="assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> 
    </div>
  )
}
