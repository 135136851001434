import React from 'react'

export default function About() {
  return (
    <div>
      <section class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
                <div class="about_wrapper">
                    <figure class="mb-0 about-image1">
                        <img src="assets/images/about-us.jpg" alt="" class="" />
                    </figure>
                    <figure class="mb-0 about-image2">
                        <img src="assets/images/about-us-2.png" alt="" class="img-fluid" />
                    </figure>
                    <div class="image-circle">
                        <div class="years">
                            <span class="text-white value counter">30</span>
                            <sup class="text-white mb-0 plus">+</sup>
                            <span class="text-white text">Years of Experience</span>
                        </div>
                        <figure class="mb-0 about-imagecircle">
                            <img src="assets/images/about-imagecircle.png" alt="" class="" /> 
                        </figure>
                    </div>
                    <figure class="mb-0 about-dot">
                        <img src="assets/images/about-dot.png" alt="" class="img-fluid" />
                    </figure>
                    <figure class="mb-0 about-leftcircle">
                        <img src="assets/images/about-leftcircle.png" alt="" class="img-fluid" />
                    </figure>
                    <figure class="mb-0 about-layer">
                        <img src="assets/images/about-layer.png" alt="" class="img-fluid" />
                    </figure>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
                <div class="about_content" data-aos="fade-right">
                    <h6>About us</h6>
                    <h2 class="text-white">Best PCD Pharma Franchise Company in India</h2>
                    <p class="text1"><b>Best PCD Pharma Franchise Company</b> – Avosia Pharma is one of the leading Pharma Company with a most exclusive range of products. We are a leading pharmaceutical company dedicated to improving health and providing top-quality formulations. Our company is dealing in 500+ pharma products and the products are 100% effective and efficient. We are offering tablets, Capsules, Injections, Syrup, Dry Syrup, etc.</p>
                    <a class="read_more text-white text-decoration-none" href="/about-us">Read More
                        <figure class="mb-0"><img src="assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <figure class="mb-0 about-rightimage">
        <img src="assets/images/about-rightimage.png" alt="" class="img-fluid" />
    </figure>
    <figure class="mb-0 about-rightcircle">
        <img src="assets/images/about-rightcircle.png" alt="" class="img-fluid" />
    </figure>
</section>
    </div>
  )
}
