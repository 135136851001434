import React from 'react'

export default function Testimonial() {
    return (
        <div>
            <section class="testimonial-section position-relative">
                <figure class="service-leftlayer mb-0">
                    <img src="assets/images/service-leftlayer.png" alt="" class="img-fluid" />
                </figure>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12">
                            <div class="testimonial_wrapper position-relative" data-aos="fade-right">
                                <figure class="service-rightcircle mb-0">
                                    <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                </figure>
                                <div class="circle_wrapper position-relative">
                                    <div class="center-circle">
                                        <figure class="testimonial-centerimage mb-0">
                                            <img src="assets/images/testimonial-centerimage.png" alt="" class="img-fluid" />
                                        </figure>
                                    </div>
                                    <figure class="testimonial-image1 position-absolute mb-0">
                                        <img src="assets/images/testimonial-image1.png" alt="" class="img-fluid" />
                                    </figure>
                                    <figure class="testimonial-image2 position-absolute mb-0">
                                        <img src="assets/images/testimonial-image2.png" alt="" class="img-fluid" />
                                    </figure>
                                    <figure class="testimonial-image3 position-absolute mb-0">
                                        <img src="assets/images/testimonial-image3.png" alt="" class="img-fluid" />
                                    </figure>
                                    <figure class="testimonial-image4 position-absolute mb-0">
                                        <img src="assets/images/testimonial-image4.png" alt="" class="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12">
                            <div class="testimonial_contentwrapper">
                                <div class="heading">
                                    <h6>TESTIMONIALS</h6>
                                    <h2>What Our Customers Have To Say</h2>
                                </div>
                                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="testimonial_content">
                                                <div class="content-box">
                                                    <p>"Partnering with Avosia Pharma transformed our business. Their wide range of products, exceptional marketing support, and strong reputation propelled us to incredible success."</p>
                                                    <div class="content">
                                                        <div class="circle">
                                                            <figure class="testimonial-quote mb-0">
                                                                <img src="assets/images/testimonial-quote.png" alt="" class="img-fluid" />
                                                            </figure>
                                                        </div>
                                                        <div class="designation-outer">
                                                            <span>Karan</span>
                                                            <p class="text-size-18 mb-0">Happy Client</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="testimonial_content">
                                                <div class="content-box">
                                                    <p>"As a healthcare professional, I'm impressed by the quality and effectiveness of products. Their dedication to delivering top-notch pharmaceuticals and unwavering support makes them our trusted partner in meeting our patients' healthcare needs."</p>
                                                    <div class="content">
                                                        <div class="circle">
                                                            <figure class="testimonial-quote mb-0">
                                                                <img src="assets/images/testimonial-quote.png" alt="" class="img-fluid" />
                                                            </figure>
                                                        </div>
                                                        <div class="designation-outer">
                                                            <span>Vijay</span>
                                                            <p class="text-size-18 mb-0">Happy Client</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pagination-outer">
                                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                            <i class="fa-solid fa-arrow-left"></i>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                            <i class="fa-solid fa-arrow-right-long"></i>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <figure class="service-rightlayer mb-0">
                    <img src="assets/images/service-rightlayer.png" alt="" class="img-fluid" />
                </figure>
            </section>
        </div>
    )
}
