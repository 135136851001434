import React from 'react'

export default function Benefits() {
    return (
        <div className='ben' style={{  backgroundImage: `url("assets/images/bg-images-one.jpg")`}}>
            <div className="container">
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='benefit-content'>
                            <h4>Benefits</h4>
                            <h2>Key Benefits of Connecting with the PCD Pharma Franchise Company </h2>
                            <p>With a prominent name in the industry, Avosia Pharma brings credibility and trust to its franchise partners.</p>
                            <ul>
                                <li>Low Investment</li>
                                <li>Marketing Support</li>
                                <li>Exclusive Product Range</li>
                                <li>Monopoly Rights</li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='benefit-image'>
                            <div className='circles'>
                                 25+ Years
                            </div>
                        <img src="assets/images/banefits-two.png" alt=''></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
