import React from 'react'

export default function Category() {
  return (
    <div>
      <section class="project-section">
    <figure class="offer-toplayer mb-0">
        <img src="assets/images/offer-toplayer.png" alt="" class="img-fluid" />
    </figure>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="project_content" data-aos="fade-right">
                    <h6>PRODUCT CATEGORY</h6>
                    <h2>Our Latest Product Category</h2>
                    <p>Explore our extensive product category, offering a diverse range of high-quality pharmaceutical solutions for various therapeutic areas.</p>
                    <figure class="offer-circleimage mb-0">
                        <img src="assets/images/offer-circleimage.png" alt="" class="img-fluid" />
                    </figure>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="owl-carousel owl-theme">
                <div class="item">
                    <div class="case-box1 case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/tablets.png" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Tablets</h5>
                           
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/capsules.jpg" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Capsule</h5>
                          
                            
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box2 case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/injection.jpg" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Injection</h5>
                        
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/syrup.jpg" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Syrup</h5>
                           
                          
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/ointment.jpg" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Ointment</h5>
                          
                           
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box1 case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/protein-powder.png" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Powder</h5>
                           
                           
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="case-box overlay">
                        <div class="overlay-image">
                            <figure class="image mb-0">
                                <img src="assets/images/soft-gel.jpg" alt="" class="" />
                            </figure>
                        </div>
                        <div class="content">
                            <span class="text-white">Category</span>
                            <h5 class="text-white">Softgel Capsules</h5>
                         
                           
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    <figure class="offer-bottomlayer mb-0">
        <img src="assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
    </figure>
</section>
    </div>
  )
}
