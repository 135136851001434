import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import Footer from './common/Footer'
import { Link, useParams } from 'react-router-dom';

export default function Allcategory() {
    const { category } = useParams();

    const [cate, setCate] = useState([])
    const [products, setProducts] = useState([])
    const allcategory = () => {
        fetch('https://dashboard.avosiapharma.com/api/allCategories').then((res) => res.json())
            .then((result) => {
                setCate(result.cat)
                //console.log(result.cat)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const allproducts = () => {
        fetch(`https://dashboard.avosiapharma.com/api/allProducts/${category}`).then((res) => res.json())
            .then((result) => {
                setProducts(result.pro)

            })
            .catch((err) => {
                console.log(err)
            })
    }


    useEffect(() => {
        allcategory()
        allproducts()
    }, [category])



    return (
        <>
            <div class="sub-banner sub-banner1">
                <Header />
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content" data-aos="fade-up">
                                    <figure class="about-rightcircle service-rightcircle mb-0">
                                        <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">{category}</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>
                <figure class="sub-bannerrightlayer mb-0">
                    <img src="assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
                </figure>

            </div>

            <div class="container mt-30">
                <div class="row">
                    <div class="col-lg-3">
                       <div class="sidebar">
                        <h4>Human Product List</h4>
                       <ul className=""> 
                                        <li ><Link to="/tablets">Tablets</Link></li>
                                        <li ><Link  to="/capsules">Capsules</Link></li>
                                        <li ><Link  to="/injections">Injections</Link></li>
                                        <li ><Link  to="/syrup">Syrup</Link></li>
                                        <li ><Link  to="/dry-syrup">Dry Syrup</Link></li>
                                        <li ><Link  to="/Softget-Capsules">Softget Capsules</Link></li>
                                        <li ><Link  to="/Paedeatric-drops">Paedeatric Drops</Link></li>
                                        <li ><Link  to="/Sachets">Sachets</Link></li>
                                        <li ><Link  to="/others">Others</Link></li>
                                       
                                    </ul>
                       </div>
                       <div class="sidebar">
                        <h4>Veterinary product list</h4>
                       <ul className=""> 
                       <li className=""><Link  to="/tablet-bolus">Tablet Bolus</Link></li>
                                       <li ><Link  to="/liquid-injections">Liquid Injections</Link></li>
                                       <li ><Link to="/dry-injections">Dry Injections</Link></li>
                                       <li ><Link  to="/feed-supplements">Feed Supplements</Link></li>
                                       <li ><Link to="/veterinary-other">Other</Link></li>
                                       
                                    </ul>
                       </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="products">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>IMAGE</th>
                                        <th>NAME</th>
                                        <th>COMPOSITION</th>
                                        <th>PACKING</th>
                                        <th>MRP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                       products.map((pros)=>{
                                        return(
                                            <tr>
                                        <td>{pros.image?(<img width="70px" src={`https://dashboard.avosiapharma.com/${pros.image}`} />):(<img src="assets/images/dummy-225x300.jpg"  width="70px" />)}</td>
                                        <td><Link to={`/product/${pros.slug}`} style={{color:"#000"}}>{pros.name}</Link></td>
                                        <td>{pros.composition}</td>
                                        <td>{pros.packing}</td>
                                        <td>{pros.price}</td>
                                    </tr>
                                        )
                                       }) 
                                    }
                                    
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="all_categorys">
                            <h4>All Category</h4>
                            <ul>
                                {
                                    cate.map(c => {
                                        return (

                                            <li key={c.id}><Link to={`/${c.slug}`}>
                                                <img src={`https://dashboard.avosiapharma.com/${c.image}`}></img>
                                                <p>{c.name}</p></Link>
                                                </li>

                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>




            <Footer />

        </>
    )
}
