import React from 'react'
import Header from '../component/common/Header'
import Footer from '../component/common/Footer'
import Aboutuspage from '../component/Aboutuspage'

export default function Aboutus() {
  return (
    <>
      <div class="sub-banner sub-banner1">
       
        <Header />
        <section class="banner-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="banner_content" data-aos="fade-up">
                  <figure class="about-rightcircle service-rightcircle mb-0">
                    <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                  </figure>
                  <h1 class="text-white">About Us</h1>
                  <p>As the Best PCD Pharma Franchise Company, Avosia Pharma is offering the best services.</p>
                  <div class="box">
                    <span class="mb-0">Home</span>
                    <figure class="mb-0 arrow"><img src="assets/images/button-arrow.png" alt="" class="img-fluid" /></figure>
                    <span class="mb-0 box_span">About</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <figure class="banner-circleright mb-0">
          <img src="assets/images/banner-circleright.png" class="img-fluid" alt="" />
        </figure>
        <figure class="sub-bannerrightlayer mb-0">
          <img src="assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
        </figure>

      </div>


     <Aboutuspage />




      <Footer />

    </>
  )
}
