import React from 'react'

export default function Aboutuspage() {
  return (
    <div>
      <section class="aboutus-section">
    <figure class="offer-toplayer mb-0">
        <img src="assets/images/offer-toplayer.png" alt="" class="img-fluid" />
    </figure>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
                <div class="about_wrapper">
                    <figure class="mb-0 aboutus-image1">
                        <img src="assets/images/aboutus-image1.jpg" alt="" class="" />
                    </figure>
                    <figure class="mb-0 aboutus-image2">
                        <img src="assets/images/aboutus-image2.jpg" alt="" class="img-fluid" /> 
                    </figure>
                    <div class="image-circle">
                        <div class="years">
                            <span class="text-white value counter">30</span>
                            <sup class="text-white mb-0 plus">+</sup>
                            <span class="text-white text">Years of Experience</span>
                        </div>
                        <figure class="mb-0 about-imagecircle">
                            <img src="assets/images/about-imagecircle.png" alt="" class="" />
                        </figure>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
                <div class="service_content position-relative aos-init aos-animate" data-aos="fade-right">
                    <figure class="service-rightcircle mb-0">
                        <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                    </figure>
                    <h6>About Us</h6>
                   
                    <p>As a trusted name in the industry, we offer excellent opportunities for PCD Pharma Franchise and Third Party Manufacturing. We are committed to quality and innovation and strive to improve healthcare. Our company is the best Pharma Franchise Company that offers the most effective range of medicines.</p>
                    <ul class="list-unstyled mb-0">
                        <li class="text"><i class="circle fa-duotone fa-check"></i>Our Company is a WHO-GMP, GLP-certified Company</li>
                        <li class="text"><i class="circle fa-duotone fa-check"></i>All the products are DCGI Approved</li>
                        <li class="text"><i class="circle fa-duotone fa-check"></i>100% efficacy of the pharma products</li>
                        <li class="text text1"><i class="circle fa-duotone fa-check"></i>Best Monopoly Rights are offered to the associates</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
    <figure class="offer-bottomlayer mb-0">
        <img src="assets/images/offer-bottomlayer.png" alt="" class="img-fluid" />
    </figure>
</section>
    </div>
  )
}
