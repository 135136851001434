import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';

export default function Product() {
    const { slug } = useParams();
    const [pros, setPro] = useState('')
    const [cate, setCate] = useState([])
    const [product, setProduct] = useState([])
    const products = () => {
        fetch(`https://dashboard.avosiapharma.com/api/product/${slug}`).then((res) => res.json())
            .then((result) => {
                setPro(result.pro)
                //console.log(result.pro)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const allproducts = () => {
        fetch('https://dashboard.avosiapharma.com/api/allproduct').then((ress) => ress.json())
            .then((results) => {
                setProduct(results.products)
                console.log(results.products)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const allcategory = () => {
        fetch('https://dashboard.avosiapharma.com/api/allCategories').then((res) => res.json())
            .then((result) => {
                setCate(result.cat)
                //console.log(result.cat)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        allproducts()
        products()
        allcategory()
    }, [slug])

    return (
        <>

            <div class="sub-banner sub-banner1">
                <Header />
                <section class="banner-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="banner_content" data-aos="fade-up">
                                    <figure class="about-rightcircle service-rightcircle mb-0">
                                        <img src="assets/images/service-rightcircle.png" alt="" class="img-fluid" />
                                    </figure>
                                    <h1 class="text-white">{pros.name}</h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure class="banner-circleright mb-0">
                    <img src="assets/images/banner-circleright.png" class="img-fluid" alt="" />
                </figure>
                <figure class="sub-bannerrightlayer mb-0">
                    <img src="assets/images/sub-bannerrightlayer.png" alt="" class="img-fluid" />
                </figure>
            </div>


            <section>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 mt-30">
                            <div class="sidebar">
                                <h4>Human Product List</h4>
                                <ul className="">
                                    <li ><Link to="/tablets">Tablets</Link></li>
                                    <li ><Link to="/capsules">Capsules</Link></li>
                                    <li ><Link to="/injections">Injections</Link></li>
                                    <li ><Link to="/syrup">Syrup</Link></li>
                                    <li ><Link to="/dry-syrup">Dry Syrup</Link></li>
                                    <li ><Link to="/Softget-Capsules">Softget Capsules</Link></li>
                                    <li ><Link to="/Paedeatric-drops">Paedeatric Drops</Link></li>
                                    <li ><Link to="/Sachets">Sachets</Link></li>
                                    <li ><Link to="/others">Others</Link></li>

                                </ul>
                            </div>
                            <div class="sidebar">
                                <h4>Veterinary product list</h4>
                                <ul className="">
                                    <li className=""><Link to="/tablet-bolus">Tablet Bolus</Link></li>
                                    <li ><Link to="/liquid-injections">Liquid Injections</Link></li>
                                    <li ><Link to="/dry-injections">Dry Injections</Link></li>
                                    <li ><Link to="/feed-supplements">Feed Supplements</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="product-image">

                                        {
                                            pros.image ? (<img src={`https://dashboard.avosiapharma.com/${pros.image}`} />)
                                                :
                                                (<img src="/assets/images/dummy-225x300.jpg" />)
                                        }
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="product-description">
                                        <h2>{pros.name}</h2>
                                        <h4><b>Product Name : </b> {pros.name} </h4>

                                        <h4><b>Composition : </b> {pros.composition} </h4>
                                        <h4><b>Packing : </b> {pros.packing} </h4>
                                        <h4><b>Price : </b> {pros.price} </h4>
                                        <h4>Some other Products</h4>
                                        {/* <ul>
                                            {
                                                product.map((pro) => {
                                                    return (
                                                        <li key={pro.id}><Link to={`/product/${pro.slug}`} style={{ color: "#fff" }}>{pro.name}</Link></li>
                                                    )
                                                })
                                            }
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>






            <Footer />
        </>
    )
}
